document.addEventListener( 'DOMContentLoaded', function () {

    const searchTrigger = document.querySelector('.search-trigger');
    const searchForm = document.querySelector('.search-form');
    const searchTriggerIcon = document.querySelector('.search-trigger i.far');
    const searchSubmit = document.querySelector('.search-submit');
    const searchInput = document.querySelector('input.search');
    
    searchTrigger.addEventListener('click', () => {
        toggleSearch();
     });
  
     searchSubmit.addEventListener('click', () => {
        searchForm.submit();
     });
  
  
    // Show & Hide Toggle Menu Function
    function toggleSearch() {
       searchTrigger.classList.toggle('open');
       searchForm.classList.toggle('open');
  
       searchTriggerIcon.classList.toggle('fa-times')
       searchTriggerIcon.classList.toggle('fa-search')
  
       searchInput.focus();
    }
  
  });
  